import './App.css';
import React from 'react';
import Points from './Points';
import WithControls from './WithControls';
import {adjustFocus, calculateAngle, inDoorway, repaintFocus} from './Canvas';
import openSocket from 'socket.io-client';

const socket = openSocket(wsURL());
const MAX_RIGHT = 1200;
const MAX_DOWN = 550;
const MAX_RIGHT_PHONE = 950;
const MAX_DOWN_PHONE = 390;

function wsURL() {
  return ((window.location.protocol === "https:") ? "wss://" : "ws://") + window.location.host;
}

function rnd(max, min) {
  min = min || 0;
  return Math.floor(Math.random() * (max - min)) + min;
};

function randomColor() {
  return { r: rnd(150), g: rnd(150), b: rnd(150) };
}

function toHex(c) {
  var hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

function rgbToHex(color) {
  return "#" + toHex(color.r) + toHex(color.g) + toHex(color.b);
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      repColor: rgbToHex(randomColor()),
      points: new Points(this.updates),
      nextRepName: "",
      hit: {shape: null, x: -1, y: -1},
      movingCircle: false,
      movingNose: false
    }
    this.addRepresentative = this.addRepresentative.bind(this);
    this.repColorChange = this.repColorChange.bind(this);
    this.repNameChange = this.repNameChange.bind(this);
    this.mouseMove = this.mouseMove.bind(this);
    this.moveCircle = this.moveCircle.bind(this);
    this.mouseDown = this.mouseDown.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.touchStart = this.touchStart.bind(this);
    this.touchEnd = this.touchEnd.bind(this);
    this.touchMove = this.touchMove.bind(this);
    this.clear = this.clear.bind(this);
    socket.on('message', (msg) => {
      this.state.points.receiveUpdate(msg);
      this.setState(this.state.points);
    });
  }

  updates(msg) {
    socket.emit('message', msg);
  }

  componentDidMount() {
    this.state.points.initFromDB(
      () => this.setState({points: this.state.points}));
  }

  repColorChange(e) {
    this.setState({ repColor: e.target.value});
  }

  repNameChange(e) {
    this.setState({ nextRepName: e.target.value });
  }

  touchStart(event) {
    var touches = event.changedTouches;
    if (touches.length > 0) {
      // check if we've hit a rep
      var canvasDom = document.getElementById("ic-canvas");
      var x = Math.round(touches[0].pageX - 8);
      var y = Math.round(touches[0].pageY - canvasDom.offsetTop - 8);
      this.collide(x, y, true);
      this.setState({movingCircle: true});
    }
  }

  touchEnd(event) {
    if (this.state.hit.shape !== null && inDoorway(this.state.hit.x, this.state.hit.y) && this.state.movingCircle) {
      this.state.points.remove(this.state.hit.shape.id);
    }
    this.setState({
      points: this.state.points,
      hit: { shape: this.state.hit.shape, x: -1, y: -1 },
      movingCircle: false,
      movingNose: false
    });
    this.collide(-1, -1, false);
  }

  touchMove(event) {
    var touches = event.changedTouches;

    if (touches.length > 0 && this.state.hit.shape && this.state.hit.x !== -1) {
      var canvasDom = document.getElementById("ic-canvas");
      var offsetTop = canvasDom.offsetTop + 8;
      var offsetLeft = 8;
      var cw = MAX_RIGHT;
      var ch = MAX_DOWN;
      var x = Math.round(Math.min(cw, Math.max(touches[0].pageX - offsetLeft, 0)));
      var y = Math.round(Math.min(ch, Math.max(touches[0].pageY - offsetTop, 0)));
      if (!this.state.movingNose) {
        this.moveCircle(this.state.hit.shape, x, y);
      } else {
        this.moveNose(this.state.hit.shape, x, y);
      }
      this.setState({hit: {shape: this.state.hit.shape, x: x, y: y}});
    }
  }

  mouseMove(event) {
    var canvasDom = document.getElementById("ic-canvas");
    var x = Math.min(MAX_RIGHT, event.clientX - 8);
    var y = Math.min(MAX_DOWN, event.clientY - canvasDom.offsetTop - 8);
    if (this.state.hit.shape && this.state.hit.x !== -1) {
      if (!this.state.movingNose) {
        this.moveCircle(this.state.hit.shape, x, y);
      } else {
        this.moveNose(this.state.hit.shape, x, y);
      }
      this.setState({hit: {shape: this.state.hit.shape, x: this.state.hit.x, y: this.state.hit.y}});
    } else {
      this.collide(x, y, false);
    }
  }

  mouseDown(event) {
    if (this.state.hit.shape) {
      var canvasDom = document.getElementById("ic-canvas");
      var x = event.clientX - 8;
      var y = event.clientY - canvasDom.offsetTop - 8;

      this.setState({
        hit: { shape: this.state.hit.shape, x: x, y: y },
        movingCircle: true
      });
    }
  }

  mouseUp(event) {
    if (this.state.hit.shape !== null && inDoorway(this.state.hit.x, this.state.hit.y) && this.state.movingCircle) {
      this.state.points.remove(this.state.hit.shape.id);
    }
    this.setState({
      points: this.state.points,
      hit: { shape: this.state.hit.shape, x: -1, y: -1 },
      movingCircle: false,
      movingNose: false
    });
  }

  moveCircle(shape, x, y) {
    var points = this.state.points;
    var hit = this.state.hit;
    points.updatePosition(shape.id, x - hit.x, y - hit.y);
    hit.x = x;
    hit.y = y;
    this.setState({ points, hit });
    repaintFocus(shape, x, y);
  };

  moveNose(shape, x, y) {
    var points = this.state.points;
    points.updateDir(shape.id, calculateAngle(shape, x, y));
    this.setState({ points });
  };

  collide(x, y, saveHit) {
    let hitShape = adjustFocus(x, y, (movingNose) => this.setState({ movingNose }));
    if (!saveHit) {
      x = -1;
      y = -1;
    }
    this.setState({hit: {shape: hitShape, x: x, y: y}})
  };

  addRepresentative(e) {
    const self = this;
    self.setState({
      repColor: rgbToHex(randomColor()),
      nextRepName: ''
    })
    this.state.points.add({
      text: this.state.nextRepName,
      x: rnd(800 / 2 + 50, 800 / 2 - 50),
      y: rnd(MAX_DOWN / 3 + 50, MAX_DOWN / 3 - 50),
      dir: rnd(360),
      color: this.state.repColor
    }, () => self.setState({points: this.state.points}));
  }

  keyPress(e) {
    if (e.key === 'Enter') {
      this.addRepresentative(e);
    }
  }

  clear(e) {
    this.state.points.clear();
    this.setState({points: this.state.points});
  }

  render() {
    return (
      <WithControls points={this.state.points}
        repColor={this.state.repColor}
        repName={this.state.nextRepName}
        addRepresentative={this.addRepresentative}
        repColorChange={this.repColorChange}
        repNameChange={this.repNameChange}
        touchStart={this.touchStart}
        touchEnd={this.touchEnd}
        touchMove={this.touchMove}
        mouseMove={this.mouseMove}
        mouseDown={this.mouseDown}
        mouseUp={this.mouseUp}
        keyPress={this.keyPress}
        clear={this.clear}
      />
    );
  }
}

export default App;
